import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { RelationshipFinder } from '../controls/search';
import { TextControl } from '../controls/text';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import { prepareAnswers } from '../libs/workflow';

export const Beneficiary = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { answers, setAnswers } = useState(false);

    const [mustRevalidate, setMustRevalidate] = useState(false);


    useEffect(() => {

        scrollUp();
        setLayout({ currentScreen: 'beneficiary', screenReady: false });
        //onsole.info("Sesh info", session);
        let newAnswers = prepareAnswers({ session: session });
        setSession(newAnswers);


    }, []);



    const onChange = ({ id, value }) => {

        if ("beneficiary_minor" === id) {
            if ("No" === value) {
                setSession({
                    beneficiary_trustee: "n/a"
                });
            } else {
                setSession({
                    beneficiary_trustee: ""
                });
            }
        }
        if ("beneficiary_contingent" === id) {
            if ("No" === value) {
                setSession({
                    beneficiary_contingent_name: "n/a",
                    beneficiary_contingent_relationship: "n/a",
                });
            } else {
                setSession({
                    beneficiary_contingent_name: "",
                    beneficiary_contingent_relationship: ""
                });
            }
        }

    };
    const validateScreen = (options) => {

        setMustRevalidate(true);

    }


    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Beneficiary</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} label="Name of your beneficiary" title="Please provide us with the name of your beneficiary" field="beneficiary_name" placeholder="Name" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <RelationshipFinder label="Relationship" title="Please provide us with your relation with the beneficiary" field="beneficiary_relationship" placeholder="Relationship" onChange={onChange} required={true} type="text" />
                        {/* <TextControl mustRevalidate={mustRevalidate} label="Relationship" title="Please provide us with your relation with the beneficiary" field="beneficiary_relationship" placeholder="Relationship" onChange={onChange} required={true} type="text" /> */}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <RadioControl mustRevalidate={mustRevalidate} label="Is your beneficiary a minor?" type="yesno" helpMessage="Answer yes if your beneficiary is currently under 18 years old." onChange={onChange} field="beneficiary_minor" />
                    </Col>
                </Row>
                {"Yes" === session.beneficiary_minor &&
                    <Row className="mt-2">
                        <Col className="col-lg-12 col-xl-10">
                            <TextControl label="Trustee" title="The trustee of your beneficiary" field="beneficiary_trustee" placeholder="Trustee" onChange={onChange} required={true} type="text" />
                        </Col>
                    </Row>
                }

                <Row className="mt-2">
                    <Col>
                        <RadioControl mustRevalidate={mustRevalidate} label="Do you want to specify a contingent beneficiary?" type="yesno" onChange={onChange} field="beneficiary_contingent" />
                    </Col>
                </Row>
                {"Yes" === session.beneficiary_contingent &&
                    <>
                        <Row className="mt-2">
                            <Col className="col-lg-12 col-xl-10">
                                <TextControl label="Contingent beneficiary" title="The name of the contingent beneficiary" field="beneficiary_contingent_name" placeholder="Contingent name" onChange={onChange} required={true} type="text" />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="col-lg-12 col-xl-10">
                                <TextControl label="Relationship with contingent beneficiary" title="Relationship with the contingent beneficiary" field="beneficiary_contingent_relationship" placeholder="Relationship" onChange={onChange} required={true} type="text" />
                            </Col>
                        </Row>
                    </>
                }
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
