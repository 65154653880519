import React, {
  useEffect,
  useReducer,
  useState,
} from 'react';

import * as config from './config';
import * as api from './libs/api';
import { getCredentials } from './libs/auth.js';
import {
  getBranding,
  getMarketingTags,
} from './libs/branding';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from './libs/contexts';
import { getInitialQuote } from './libs/insurance';
import * as ui from './libs/ui';
import * as workflow from './libs/workflow.js';
import { QuickTermRoutes } from './products/quickterm-router.jsx';

export const SampleAuth = () => {

    const [tokens, setTokens] = useState(false);

    useEffect(() => {

        (async () => {
            let credentials = await getCredentials();
            //onsole.info(credentials);
            setTokens(credentials);
        })();


    }, []);

    return (<>Authenticated?
        {tokens ?
            <>yes</>
            :
            <>no</>
        }
    </>);
}

export const LinkShopper = ({ isDesktop, query }) => {

    const [session, setSession] = useReducer(stateReducer, {});
    const [layout, setLayout] = useReducer(stateReducer, {});
    const [branding, setBranding] = useReducer(stateReducer, {});
    const [happyPath, setHappyPath] = useState(false);
    const [saved, setSaved] = useState(false);
    //const [hasBaseCss, setHasBaseCss] = useState(false);
    //const [hasCustom, setHasCustomCss] = useState(false);
    const [geo, setGeo] = useState({});
    const [insuranceData, setInsuranceData] = useState({});
    const [notifications] = useState({});
    const [initialized, setInitialized] = useReducer(stateReducer, {
        branding: false,
        geo: false,
        insuranceData: false,
        layout: false,
        notifications: false,
        session: false
    });

    useEffect(() => {

        setHappyPath(workflow.getHappyPath({ product: session.product, config: config.brokerConfig }));

        if (undefined === geo || undefined === geo.province_code) {

            api.getGeoLocation({
                next: (data) => {
                    setGeo(data);
                    setInitialized({ geo: true });
                }
            });

        }

        if (undefined === layout || undefined === layout.name) {

            let newLayout = ui.getInitialLayoutInfo({ query: query });
            newLayout.happyPath = { ...happyPath };
            newLayout.currentScreen = 'welcome';
            if ("jump" === query.entry) {
                newLayout.specialView = "jump";
            }
            newLayout.isDesktop = isDesktop;
            setLayout(newLayout);
            setInitialized({ layout: true });
        }

        if (undefined === branding || undefined === branding.ab) {

            //onsole.info("Setting branding");
            let newBranding = getBranding({ verbose: false });

            if (newBranding) {
                setBranding(newBranding);
                setInitialized({ branding: true });
            }

        }

        const onSessionData = ({ sessionData, isJump }) => {

            sessionData.type = "session";

            if (isJump) {
                sessionData.jumped = "yes";

                //todo refactor, needs better defaults
                if (undefined === sessionData.term) {
                    sessionData.term = "10";
                }
                if (undefined === sessionData.term_selected) {
                    sessionData.term_selected = "term" + sessionData.term;
                }

            }

            if (undefined === sessionData.product) {
                sessionData.product = "quickterm";
            }

            //dev
            //sessionData.first_name = "Ted";
            //sessionData.last_name = "Cetera";
            //sessionData.email = "jlmartel@wawanesa.com";
            //sessionData.phone = "(204) 222-3333";
            //sessionData.premium = "$18.30";
            //sessionData.facevalue = 123456;
            //sessionData.dob = "1980-01-01";
            //sessionData.term = "10";
            //sessionData.age = 43;
            //sessionData.disqualified = 'mismatch';
            setSession(sessionData);
            setInitialized({ session: true });

            api.getReferenceData({
                verbose: session.verbose, token: sessionData.token, next: (data) => {
                    setInsuranceData(data);
                    setInitialized({ insuranceData: true });
                }
            });
        }

        if (undefined === session || undefined === session.id) {

            let isJump = "jump" === query.entry;
            let quote = getInitialQuote({ fromJump: isJump });
            let apiCall;

            if (isJump) {
                quote.jump_identifier = query.token;
                quote.jump_exp = query.exp;
                quote.jump_entry = query.entry;
                apiCall = api.continueSession;
            } else {
                apiCall = api.createSession;
            }

            apiCall({
                quote: quote,
                next: (sessionData) => onSessionData({ sessionData: sessionData, isJump: isJump })
            });

        }

    }, []);


    useEffect(() => {

        if (undefined !== session && undefined !== session.product) {


            setSession({
                happyPath: workflow.getHappyPath({ product: session.product, config: config.brokerConfig })
            });

        }

    }, [session.product]);


    useEffect(() => {




        if (session.happyPath && Object.keys(session.happyPath).length > 0) {
            let k = workflow.attachHappyPath({ happyPath: session.happyPath });
            //onsole.info("Attach path", k);
            setLayout(k);
        }


    }, [session.happyPath]);


    useEffect(() => {

        if (geo.province_code) {
            //todo check device type here or re-apply the customization on mobile detection
            let isMobile = false;
            setLayout(ui.customizeLayout({ brandingData: branding, geoData: geo, isMobile: isMobile }));
        }

    }, [geo]);

    useEffect(() => {

        if (session.id) {

            if (layout.name && undefined === session.layout) {
                setSession({
                    layout: layout.name
                });
            }

            if (undefined === session.params && undefined !== layout.params) {
                setSession(getMarketingTags({ params: layout.params }));
            }

            if (branding.ab) {
                if (undefined === session.ab) {
                    setSession({
                        ab: branding.ab,
                        profile: branding.ab
                    });
                }
            }

        }


    }, [session.id, session.layout, layout.name, layout.params, branding.ab]);

    useEffect(() => {
        if (session && session.quote && geo && geo.province_code) {
            setSession(workflow.prepareGeoFields({ geoData: geo }));
        }
    }, [geo.province_code, session.quote]);

    // this is where we sent latest local changes to the cloud
    useEffect(() => {

        if (initialized.branding) {

            const syncSession = () => {
                if (session.quote) {
                    api.saveSession({
                        quote: session, token: session.token, next: (data) => {
                            if (!saved) { 
                                setSaved(true);
                            }
                        }
                    });
                }
            }

            const timer = setInterval(() => syncSession(), 5000);
            return () => clearInterval(timer);
        }
    }, [initialized, session.quote, session.asOf, saved]);

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            <GeoContext.Provider value={{ geo }}>
                <BrandingContext.Provider value={{ branding }}>
                    <InsuranceDataContext.Provider value={{ insuranceData }}>
                        <LayoutContext.Provider value={{ layout, setLayout }}>
                            <NotificationsContext.Provider value={{ notifications }}>
                                <>
                                    {initialized && initialized.branding ?
                                        <>

                                            <QuickTermRoutes />

                                        </>
                                        :
                                        <>&nbsp;</>
                                    }
                                </>
                            </NotificationsContext.Provider>
                        </LayoutContext.Provider>
                    </InsuranceDataContext.Provider>
                </BrandingContext.Provider>
            </GeoContext.Provider>
        </SessionContext.Provider>

    );
}

const LoadingScreen = () => {
    return (<>
        <div className="dot-pulse">Loading...</div>
    </>)
}