import {
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import Fuse from 'fuse.js';
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  size,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';

import { relationships } from '../reference/approve.jsx';

const ResultItem = forwardRef(({ children, active, ...rest }, ref) => {
    const id = useId();
    return (
        <div
            ref={ref}
            role="option"
            id={id}
            aria-selected={active}
            {...rest}
            style={{
                background: active ? "lightblue" : "none",
                padding: 4,
                cursor: "default",
                ...rest.style
            }}
        >
            {children}
        </div>
    );
});

const SearchBox = ({ searchIndex, hasErrors, placeholder, hasChanged, required, title, initialValue }) => {

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [activeIndex, setActiveIndex] = useState(null);

    const listRef = useRef([]);

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        open,
        onOpenChange: setOpen,
        middleware: [
            flip({ padding: 10 }),
            size({
                apply({ rects, availableHeight, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                        maxHeight: `${availableHeight}px`
                    });
                },
                padding: 10
            })
        ]
    });

    const role = useRole(context, { role: "listbox" });
    const dismiss = useDismiss(context);
    const listNav = useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
        loop: true
    });

    const {
        getReferenceProps,
        getFloatingProps,
        getItemProps
    } = useInteractions([role, dismiss, listNav]);

    const onChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (value) {
            setOpen(true);
            setActiveIndex(0);
            hasChanged(value);
        } else {
            hasChanged("");
            setOpen(false);
        }

    }

    const fuse = new Fuse(searchIndex, {
        minMatchCharLength: 3,
        threshold: 0.4,
        includeScore: true,
        keys: ['name']
    });

    const items = relationships.filter((item) => {

        if (undefined !== inputValue && inputValue.toLowerCase() && inputValue.toLowerCase().length > 2) {
            let results = fuse.search(inputValue.toLowerCase());
            for (let result of results) {
                if (item.id === result.item.id) {
                    if (result.score < 0.2) { // this is arbitrary, 0 is perfect match, 1 is everything
                        return true;
                    }
                }
            }
            return false;
        } else {
            return false;
        }

    }
    );

    useEffect(() => {

        setInputValue(initialValue); //todo double check if the effect is as intended

    }, []);

    return (
        <>
            <input className={hasErrors ? "is-invalid form-control" : "form-control"}
                {...getReferenceProps({
                    ref: refs.setReference,
                    onChange,
                    value: inputValue,
                    placeholder: placeholder,
                    title: title,
                    required: required,
                    "aria-autocomplete": "list",
                    onKeyDown(event) {
                        if (
                            event.key === "Enter" &&
                            activeIndex != null &&
                            items[activeIndex]
                        ) {
                            setInputValue(items[activeIndex].name);
                            setActiveIndex(null);
                            setOpen(false);
                        }
                    }
                })}
            />
            <FloatingPortal>
                {open && (
                    <FloatingFocusManager
                        context={context}
                        initialFocus={-1}
                        visuallyHiddenDismiss
                    >
                        <div
                            {...getFloatingProps({
                                ref: refs.setFloating,
                                style: {
                                    ...floatingStyles,
                                    background: "#eee",
                                    color: "black",
                                    overflowY: "auto"
                                }
                            })}
                        >
                            {items.map((item, index) => (
                                <ResultItem
                                    {...getItemProps({
                                        //key: item.id,
                                        ref(node) {
                                            listRef.current[index] = node;
                                        },
                                        onClick() {
                                            hasChanged(item.name);
                                            setInputValue(item.name);
                                            setOpen(false);
                                            refs.domReference.current?.focus();
                                        }
                                    })}
                                    key={index}
                                    active={activeIndex === index}
                                >
                                    {item.name}
                                </ResultItem>
                            ))}
                        </div>
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
        </>
    );
}

export const RelationshipFinder = ({ title, value, placeholder, hasErrors, onChange, required }) => {

    return (
        <SearchBox searchIndex={relationships} hasErrors={hasErrors} placeholder={placeholder} hasChanged={onChange} title={title} required={required} initialValue={value} />
    )

}